<template>
  <!-- 休闲养生 -->
  <div class="xx_index">
    <Carousel :list="lbImgs" :status="2"></Carousel>
    <div class="xx_index_nav">
      <div class="nav_title">
        <div class="yw yw_style">business</div>
        <div class="cn cn_style">
          <span style="color:#d4237a">推荐</span>商家列表
        </div>
      </div>
      <ul class="nav_list">
        <li v-for="(item, idx) in sjList" :key="idx">
          <a :href="'#/xx_business?currentIdx=' + 0 + '&currentId=' + item.id" @click="toLink(67)">
            <div class="li_img">
              <img :src="item.site_images" alt="" />
            </div>
            <div class="li_tit ellipsis">
              {{ item.title }}
            </div>
          </a>
        </li>
      </ul>
      <a href="#/xx_business" class="nav_btn centerText"
        ><span
          >查看更多商家&nbsp;<i class="el-icon-arrow-right"></i
          ><i class="el-icon-arrow-right"></i></span
      ></a>
    </div>
    <div class="xx_index_nav">
      <div class="nav_title">
        <div class="yw yw_style">REGION</div>
        <div class="cn cn_style">
          <span style="color:#d4237a">推荐</span>区域会所
        </div>
      </div>
      <ul class="nav_list">
        <li v-for="(item, idx) in qyList" :key="idx">
          <a :href="'#/xx_region?currentIdx=' + 0 + '&currentId=' + item.id">
            <div class="li_img">
              <img :src="item.site_images" alt="" />
            </div>
            <div class="li_tit ellipsis">
              {{ item.title }}
            </div>
          </a>
        </li>
      </ul>
      <a href="#/xx_region" class="nav_btn centerText"
        ><span
          >查看更多区域&nbsp;<i class="el-icon-arrow-right"></i
          ><i class="el-icon-arrow-right"></i></span
      ></a>
    </div>
    <div
      class="xx_index_nav xw_index_nav"
      :style="{ background: `url(${xwBg}) no-repeat center/cover` }"
    >
      <div class="nav_title">
        <div class="yw yw_style">NEWS</div>
        <div class="cn cn_style">
          新闻资讯
        </div>
      </div>
      <ul class="title_list">
        <li
          v-for="(item, idx) in xwList"
          :key="idx"
          :class="[xwActive == idx ? 'xw_xz_style' : '']"
          @mouseover="xwActive = idx"
          @click="xwActive = idx"
        >
          <span>{{ item.name }}</span>
        </li>
      </ul>
      <ul class="xw_list">
        <li v-for="(item, idx) in xwList" :key="idx" v-show="xwActive == idx">
          <ul class="xwdt-li-left">
            <li v-for="(el, i) in item.news" :key="i">
              <a :href="`#/xx_trends?detailsId=${el.id}&currentIdx=${idx + 1}`">
                <h2 class="ellipsis">{{ el.title }}</h2>
                <div class="left_date">
                  <i class="el-icon-date"></i>
                  {{ el.create_time }}
                </div>
                <h4 class="two_ellipsis">{{ el.miaoshu }}</h4>
              </a>
              <h6 class="line"></h6>
            </li>
          </ul>
          <a
            class="xwdt-li-right"
            v-if="item.tuijian"
            :href="
              `#/xx_trends?detailsId=${item.tuijian.id}&currentIdx=${idx + 1}`
            "
          >
            <div class="right-img zz_all_img">
              <img
                :src="H5_IMG_HOST + item.tuijian.cover_image"
                alt="网站定制一般要多少钱？定制网站有哪些优势？"
                style="width:auto;height:100%;transition: 0.5s;"
              />
            </div>
            <div class="right-db two_ellipsis">
              <h5>
                {{ item.tuijian.title }}
              </h5>
              <div class="db_date">
                <i class="el-icon-date"></i>
                {{ item.tuijian.create_time }}
              </div>
              <div class="db_xq">
                <i class="el-icon-circle-plus"></i> 查看详情
              </div>
            </div>
          </a>
        </li>
      </ul>
      <a
        href="#/xx_trends"
        class="nav_btn centerText"
        style="background-color:#fff;"
        ><span
          >查看更多新闻&nbsp;<i class="el-icon-arrow-right"></i
          ><i class="el-icon-arrow-right"></i></span
      ></a>
    </div>
    <div class="xx_index_nav">
      <div class="nav_title">
        <div class="yw yw_style">ABOUT US</div>
        <div class="cn cn_style">
          关于我们
        </div>
      </div>
      <ul class="gywm_list">
        <li class="list-left zz_all_img">
          <img :src="gywmData.gywmImg" alt="" style="width:100%" />
        </li>
        <li class="list-right">
          <div class="list-right-html">
            <h2>{{ gywmData.gywmTitle }}</h2>
            <h4>{{ gywmData.ziTitle }}</h4>
            <div v-html="gywmData.gywmText"></div>
          </div>
          <a href="#/xx_aboutuser" class="list-right-btn" @click="toLink(70)">
            <i class="el-icon-circle-plus"></i> 更多
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  getBasicMsg,
  getPageMsg,
  getXwData,
  getCaseList,
  getProductList
} from '@/api/api'
import Carousel from '@/common/Carousel/Carousel.vue'
export default {
  components: {
    Carousel
  },
  data () {
    return {
      lbImgs: [],
      sjList: [],
      qyList: [],
      xwActive: 0,
      xwBg: '',
      xwList: [],
      gywmData: {}
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      getBasicMsg(this.changeData()).then(item => {
        if (item.data.code == 1) {
          let id = item.data.data.menu_id
          let project = item.data.data.project_id
          getPageMsg(id).then(res => {
            if (res.data.code == 1) {
              that.lbImgs = res.data.data.lbList.lbImg
              that.gywmData = res.data.data.gywm
              that.xwBg = res.data.data.allMsg.xw_bg
            }
          })
          getXwData(project).then(res => {
            if (res.data.code == 1) {
              let arr = []
              res.data.data.forEach(el => {
                if (el.news.length != 0) {
                  arr = []
                  el.news.forEach((val, i) => {
                    if (i < 4) {
                      arr.push(val)
                    }
                  })
                  el.news = arr
                }
              })
              that.xwList = res.data.data
            }
          })
          getCaseList(1, '', project).then(res => {
            if (res.data.code == 1) {
              that.sjList = res.data.data.list
            }
          })
          getProductList(1, '', project).then(res => {
            if (res.data.code == 1) {
              that.qyList = res.data.data.list
            }
          })
        }
      })
    },
    toLink(i){
      sessionStorage.setItem(this.changeData() + 'id', i)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.xx_index {
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  font-size: 14px;
  color: #333;
  .xx_index_nav {
    width: 1200px;
    margin: 0 auto;
    .nav_title {
      text-align: center;
      position: relative;
      margin: 0.5rem 0;
      .yw {
        color: #d4237a;
      }
    }
    .nav_list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: calc(25% - 10px);
        border: 1px solid #eef3fc;
        margin-top: 10px;
        cursor: pointer;
        .li_tit {
          font-size: 0.18rem;
          color: #555555;
          padding: 0 0.1rem;
          height: 48px;
          text-align: center;
          line-height: 48px;
        }
        .li_img {
          overflow: hidden;
          width: 100%;
          height: 200px;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.4s ease-in-out;
            object-fit: cover;
          }
        }
      }
      li:hover {
        border-color: #d4237a;
        .li_img img {
          transform: scale(1.1);
        }
        .li_tit {
          background-color: #d4237a;
          color: #fff;
        }
      }
    }
    .title_list {
      display: flex;
      justify-content: center;
      max-width: 1200px;
      li {
        margin: 0 5px;
        background-color: #fff;
        border-radius: 2px;
        overflow: hidden;
        zoom: 1;
        cursor: context-menu;
        span {
          display: inline-block;
          color: #535353;
          padding: 10px 28px;
        }
      }
      .xw_xz_style {
        background-color: #d4237a !important;
        span {
          color: #fff !important;
        }
      }
    }
    .xw_list {
      margin-top: 10px;
      width: 1200px;
      li {
        display: flex;
        justify-content: space-between;
      }
      .xwdt-li-left {
        width: 63%;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: calc(44% - 10px);
          background: #ffffff;
          overflow: hidden;
          padding: 4% 3%;
          margin: 1px;
          margin-top: 20px;
          position: relative;
          a {
            width: 100%;
            h2 {
              font-size: 18px;
              font-weight: 600;
              margin-bottom: 10px;
              color: #484848;
            }
            .left_date {
              padding: 0.1rem 0 0.2rem;
              margin-bottom: 0.18rem;
              font-size: 0.2rem;
              line-height: 0.2rem;
              color: #777777;
              border-bottom: 1px dashed #dddddd;
            }
            h4 {
              font-size: 0.18rem;
              line-height: 1.5;
              color: #777777;
            }
          }
          .line {
            position: absolute;
            left: 0;
            top: 0;
            height: 2px;
            width: 0;
            background: #d4237a;
            display: block;
            transition: all 0.5s ease-in-out 0.3s;
          }
        }
        li:hover {
          h2 {
            color: #d4237a;
          }
          .line {
            width: 100%;
          }
        }
      }
      .xwdt-li-right {
        width: 30%;
        background: #fff;
        padding: 2.5%;
        height: 100%;
        margin-top: 20px;
        cursor: pointer;
        .right-img {
          width: 100%;
          height: 2.66rem;
          overflow: hidden;
          margin-bottom: 20px;
        }
        .right-db {
          font-size: 14px;
          h5 {
            font-size: 18px;
            font-weight: 600;
            color: #484848;
            margin-bottom: 10px;
          }
          h5:hover {
            color: #d4237a;
          }
          .db_date {
            padding: 0.1rem 0 0.2rem;
            margin-bottom: 0.18rem;
            font-size: 0.2rem;
            line-height: 0.2rem;
            color: #777777;
            border-bottom: 1px dashed #dddddd;
          }
          .db_xq {
            display: flex;
            align-items: center;
            i {
              font-size: 24px;
              margin-right: 5px;
              color: #b63c3c;
            }
          }
          .db_xq:hover {
            color: #d4237a;
            i {
              color: #d4237a;
            }
          }
        }
      }
    }
    .gywm_list {
      width: 100%;
      display: flex;
      padding-bottom: 50px;
      .list-left {
        width: 50%;
        box-sizing: border-box;
        overflow: hidden;
      }
      .list-right {
        width: 50%;
        color: #666666;
        font-size: 14px;
        text-align: justify;
        padding-top: 10px;
        line-height: 1.9;
        padding-left: 80px;
        .list-right-html {
          line-height: 1.8;
          h2 {
            font-size: 0.24rem;
            line-height: 1.2em;
            color: #d4237a;
            font-weight: bold;
            display: block;
            cursor: default;
          }
          h4 {
            font-size: 0.16rem;
            line-height: 2em;
            color: #d4237a;
            opacity: 0.5;
            cursor: default;
          }
        }
        .list-right-btn {
          display: flex;
          align-items: center;
          cursor: pointer;
          i {
            font-size: 24px;
            margin-right: 5px;
            color: #b63c3c;
          }
        }
        .list-right-btn:hover {
          color: #d4237a;
          i {
            color: #d4237a;
          }
        }
      }
    }
    .nav_btn {
      height: 50px;
      border: 1px solid #ccc;
      color: #333;
      width: 230px;
      font-size: 16px;
      margin: auto;
      margin-top: 50px;
      transition: 0.3s;
      cursor: pointer;
    }
    .nav_btn:hover {
      color: #fff;
      background-color: #d4237a !important;
      border: 1px solid #d4237a;
    }
  }
  .xw_index_nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding-bottom: 50px;
  }
  .yw_style {
    font-size: 0.4rem;
    line-height: 1em;
    opacity: 0.1;
    font-weight: bold;
    text-transform: uppercase;
  }
  .cn_style {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0.3rem;
    font-size: 0.3rem;
    line-height: 1.2em;
    font-weight: bold;
    white-space: nowrap;
  }
}
</style>
